import './index.css'
import React, {useState, useEffect} from 'react'
import background from './assets/background.png'
import logo from './assets/logo.png'
import logo2 from './assets/logo2.png'
import moon from './assets/moon.png'
import instagram from './assets/icons/instagram.png'
import twitter from './assets/icons/twitter.png'
import facebook from './assets/icons/facebook.png'
import linkedin from './assets/icons/linkedin.png'
import arrow from './assets/icons/arrow.png'
import MetaTags from 'react-meta-tags'

function App() {
  const [menu1Current, setMenu1Current] = useState('BTC')
  const [menu1Current2, setMenu1Current2] = useState('ETH')
  const [menu2Current, setMenu2Current] = useState('USD')
  const [menu2Current2, setMenu2Current2] = useState('EUR')
  const [btcUsd, setBtcUsd] = useState('')
  const [btcEur, setBtcEur] = useState('')
  const [ethUsd, setEthUsd] = useState('')
  const [ethEur, setEthEur] = useState('')
  const [cryptoInput, setCryptoInput] = useState('1')
  const [fiatInput, setFiatInput] = useState('')
  useEffect(() => {
    // jsonp
    let getBtcUsd = async () => {
      let r = await fetch('https://api.allorigins.win/raw?url=https://www.bitstamp.net/api/v2/ticker/btcusd')
      let res = await r.json()
      let r2 = await fetch('https://api.allorigins.win/raw?url=https://api.kraken.com/0/public/Ticker?pair=xbtusd')
      let res2 = await r2.json()
      let r3 = await fetch('https://api.allorigins.win/raw?url=https://api-pub.bitfinex.com/v2/ticker/tBTCUSD')
      let res3 = await r3.json()
      setBtcUsd((parseFloat(res.last) + parseFloat(res2.result.XXBTZUSD.c[0]) + res3[6]) / 3)
    }
    getBtcUsd()
    let getBtcEur = async () => {
      let r = await fetch('https://api.allorigins.win/raw?url=https://www.bitstamp.net/api/v2/ticker/btceur')
      let res = await r.json()
      let r2 = await fetch('https://api.allorigins.win/raw?url=https://api.kraken.com/0/public/Ticker?pair=xbteur')
      let res2 = await r2.json()
      let r3 = await fetch('https://api.allorigins.win/raw?url=https://api-pub.bitfinex.com/v2/ticker/tBTCEUR')
      let res3 = await r3.json()
      setBtcEur((parseFloat(res.last) + parseFloat(res2.result.XXBTZEUR.c[0]) + res3[6]) / 3)
    }
    getBtcEur()
    const getEthUsd = async () => {
      let r = await fetch('https://api.allorigins.win/raw?url=https://www.bitstamp.net/api/v2/ticker/ethusd')
      let res = await r.json()
      let r2 = await fetch('https://api.allorigins.win/raw?url=https://api.kraken.com/0/public/Ticker?pair=ethusd')
      let res2 = await r2.json()
      let r3 = await fetch('https://api.allorigins.win/raw?url=https://api-pub.bitfinex.com/v2/ticker/tETHUSD')
      let res3 = await r3.json()
      setEthUsd((parseFloat(res.last) + parseFloat(res2.result.XETHZUSD.c[0]) + res3[6]) / 3)
    }
    getEthUsd()
    const getEthEur = async () => {
      let r = await fetch('https://api.allorigins.win/raw?url=https://www.bitstamp.net/api/v2/ticker/etheur')
      let res = await r.json()
      let r2 = await fetch('https://api.allorigins.win/raw?url=https://api.kraken.com/0/public/Ticker?pair=etheur')
      let res2 = await r2.json()
      let r3 = await fetch('https://api.allorigins.win/raw?url=https://api-pub.bitfinex.com/v2/ticker/tETHEUR')
      let res3 = await r3.json()
      setEthEur((parseFloat(res.last) + parseFloat(res2.result.XETHZEUR.c[0]) + res3[6]) / 3)
    }
    getEthEur()
  }, [setBtcUsd, setBtcEur, setEthUsd, setEthEur])
  if (btcUsd !== '' && fiatInput !== btcUsd && cryptoInput === '1' && menu1Current === 'BTC' && menu2Current === 'USD') {
    setFiatInput(btcUsd)
  }
  return (
    <div className="App" style={{backgroundImage: 'url(' + background + ')', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      <MetaTags>
        <title>Noazet | Price converter</title>
        <meta name="description" content="Price converter"/>
      </MetaTags>
      <header>
        <a href='https://noazet.com'>
          <img id='Logo' alt='Logo' src={logo}/>
          <img id='Logo2' alt='Logo' src={logo2} style={{display: 'none'}}/>
        </a>
      </header>
      <div id='Container'>
        <div className='Group' style={{display: 'flex', height: 110}}>
          <input type='number' value={cryptoInput !== '' ? Math.round(cryptoInput * 100000000) / 100000000 : cryptoInput} onChange={(e) => {
            setCryptoInput(e.target.value)
            if (!isNaN(parseFloat(e.target.value))) {
              if (menu1Current === 'BTC') {
                if (menu2Current === 'USD') {
                  setFiatInput(parseFloat(e.target.value) * btcUsd)
                } else {
                  setFiatInput(parseFloat(e.target.value) * btcEur)
                }
              } else {
                if (menu2Current === 'USD') {
                  setFiatInput(parseFloat(e.target.value) * ethUsd)
                } else {
                  setFiatInput(parseFloat(e.target.value) * ethEur)
                }
              }
            } else {
              setFiatInput('')
            }
          }}/>
          <div className="Dropdown">
            <button>
              {menu1Current}
              <img alt='Arrow' className="Arrow" src={arrow}/>
            </button>
            <button onClick={() => {
              setMenu1Current(menu1Current2)
              setMenu1Current2(menu1Current)
              if (!isNaN(parseFloat(cryptoInput))) {
                if (menu1Current2 === 'ETH') {
                  if (menu2Current === 'USD') {
                    setFiatInput(parseFloat(cryptoInput) * ethUsd)
                  } else {
                    setFiatInput(parseFloat(cryptoInput) * ethEur)
                  }
                } else {
                  if (menu2Current === 'USD') {
                    setFiatInput(parseFloat(cryptoInput) * btcUsd)
                  } else {
                    setFiatInput(parseFloat(cryptoInput) * btcEur)
                  }
                }
              } else {
                setFiatInput('')
              }
            }} className='Secondary'>
              {menu1Current2}
            </button>
          </div>
        </div>
        <span>=</span>
        <div className='Group' style={{display: 'flex', height: 110}}>
           <input type='number' value={fiatInput !== '' ? Math.round(fiatInput * 100) / 100 : fiatInput} onChange={(e) => {
            setFiatInput(e.target.value)
            if (!isNaN(parseFloat(e.target.value)))  {
              if (menu2Current === 'USD') {
                if (menu1Current === 'BTC') {
                  setCryptoInput(parseFloat(e.target.value) / btcUsd)
                } else {
                  setCryptoInput(parseFloat(e.target.value) / ethUsd)
                }
              } else {
                if (menu1Current === 'BTC') {
                  setCryptoInput(parseFloat(e.target.value) / btcEur)
                } else {
                  setCryptoInput(parseFloat(e.target.value) / ethEur)
                }
              }
            } else {
              setCryptoInput('')
            }
          }}/>
          <div className="Dropdown">
            <button>
              {menu2Current}
              <img alt='Arrow' className="Arrow" src={arrow}/>
            </button>
            <button onClick={() => {
              setMenu2Current(menu2Current2)
              setMenu2Current2(menu2Current)
              if (!isNaN(parseFloat(fiatInput))) {
                if (menu2Current2 === 'USD') {
                  if (menu1Current === 'BTC') {
                    setFiatInput(parseFloat(cryptoInput) * btcUsd)
                  } else {
                    setFiatInput(parseFloat(cryptoInput) * ethUsd)
                  }
                } else {
                  if (menu1Current === 'BTC') {
                    setFiatInput(parseFloat(cryptoInput) * btcEur)
                  } else {
                    setFiatInput(parseFloat(cryptoInput) * ethEur)
                  }
                }
              } else {
                setCryptoInput('')
              }
            }} className='Secondary'>
              {menu2Current2}
            </button>
          </div>
        </div>
      </div>
      <div id='Icons'>
        <a href='https://instagram.com/Noazet_' target='_blank' rel="noopener noreferrer" id='Instagram'>
          <img alt='Instagram' src={instagram}/>
        </a>
        <a href='https://twitter.com/Noazet_' target='_blank' rel="noopener noreferrer" id='Twitter'>
          <img alt='Twitter' src={twitter}/>
        </a>
        <a href='https://www.facebook.com/Noazet/' target='_blank' rel="noopener noreferrer" id='Facebook'>
          <img alt='Facebook' src={facebook}/>
        </a>
        <a href='https://www.linkedin.com/company/noazet/' target='_blank' rel="noopener noreferrer" id='Linkedin'>
          <img alt='Linkedin' src={linkedin}/>
        </a>
      </div>
      <img alt='Moon' className='Moon' src={moon} style={{display: 'none', width: '100%'}}/>
      <footer>
        Copyright &copy; 2021 Noazet
      </footer>
    </div>
  )
}

export default App
